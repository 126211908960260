.right_title[data-v-74ef1ef4] {
  width: 98%;
  margin: 0 auto;
  margin-bottom: 10px;
}
.right_title h3[data-v-74ef1ef4] {
  font-size: 14px;
  padding-bottom: 10px;
}
.right_title h5[data-v-74ef1ef4] {
  font-weight: normal;
}
.right_title p[data-v-74ef1ef4] {
  font-size: 12px;
  color: red !important;
  text-align: left !important;
  padding: 5px;
}
.none-border p[data-v-74ef1ef4] {
  line-height: 30px;
  text-align: left;
  border-top: 1px solid #eee;
  text-indent: 1em;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}